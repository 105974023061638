.profile-logo {
  display: flex;
  justify-content: center;
}

.btn-circle.btn-sm {
  width: 30px;
  height: 30px;
  padding: 6px 0px;
  border-radius: 15px;
  font-size: 8px;
  text-align: center;
}

.btn-circle.btn-md {
  position: relative;
  width: 125px;
  height: 125px;
  /* padding: 7px 10px; */
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  border: 12px solid #e7dbdb;
}
input[type="file"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.profile-logo .edit-icon {
  position: absolute;
  right: 3px;
  /* margin-left: 42px; */
  bottom: 0px;
  color: grey;
}
.profile-logo .edit-icon img {
  width: 35px;
}

.profile_img {
  position: absolute;
  width: 100%;
  height: 100%;
  /* margin-left: -25%; */
  /* margin-top: -13%; */
  border-radius: 50%;
  border: 3px solid #efe8e8;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* .profile_details input {
  width: 80%;
  border: 1px solid #61ffa8;
  border-radius: 5px;
}

.profile_details .country {
  width: 7%;
  background: transparent;
  border-right: none;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  padding-right: 0px;
}

.profile_details .phn_num {
  border: 1px solid #61ffa8;
  width: 73%;
  border-left: none;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  outline: none;
  padding-left: 0px;
  background-color: transparent;
}
.profile_details .save-details-btn {
  margin-top: 5px;
  border: 2px solid #047135 !important;
  padding: 3px 0 3px;
  border-radius: 3px;
  float: right;
  text-align: center;
  width: 20%;
  color: #047135 !important;
  background: white !important;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 20%);
  border-radius: 4px;
  font-size: 15px !important;
  font-weight: 600 !important;
  background-origin: padding-box;
  border: none;
} */

.profile_details input {
  /* border-radius: 5px; */

  border: none;
  padding: 10px;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 2px 8px rgba(170, 170, 170, 0.12);
  border-radius: 8px;
}

.profile_details .label {
  color: gray;
}

.profile_details .customer-email {
  width: 80%;
}

.profile_details .save-btn {
  border: 1.5px solid #379b63;
  border-radius: 5px;
  padding: 8px 50px;
  margin: 0px 10px 0px;
  font-weight: 600;
  background-color: #379b63;
  color: #fafafa;
}
