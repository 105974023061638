/*****************
******************
REFER AND EARN!!
*****************
*****************/
/*=============================================================home-page=============================================================*/

/*reference*/
.my-7 {
  margin-bottom: 5rem;
  margin-top: 5rem;
}
.flex {
  margin: 10% 0;
  font-weight: 600;
}
.flex h5 {
  line-height: 30px;
}
.refer-btn {
  background: #047135;
  color: white;
  border: none;
  padding: 10px 34px;
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
  border-radius: 4px;
}
/*download*/
.download .flex p {
  font-size: 22px;
  font-weight: 400;
  line-height: 30px;
}
.link button {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  background: #61ffa8;
}
.link .m-number {
  font-size: 14px;
  padding: 5px 20px;
  border: 1px solid #61ffa8;
  color: #0b0909bb;
  border-radius: 4px;
  font-weight: 500;
}
