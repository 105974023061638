.account_class {
  margin: 8%;
}
.account_class .body {
  padding: 1% 1%;
}
.account_class .body .right {
  box-shadow: 0px 0px 8px #61ffa8;
  background: #fafafa;
  border: 2px solid #80ffb8;
  box-shadow: 0px 20px 52px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.element {
  /* padding:5px; */
  margin-bottom: 9px;
  border: 2px solid #61ffa8;
  border-radius: 7px;
  height: 70px;
}

.element div {
  padding: 0px;
}

.account_class .body div ul {
  padding: 0px;
}

.account_class .body .left-list .nav-link {
  padding: 0px;
  color: black;
}

.account_class .right {
  /* height: 1000px; */
  padding: 2% 2%;
  width: 72%;
  margin-left: 14px;
  box-shadow: 0px 0px 3px #61ffa8;
}
/* 
.account_class .right p {
  margin-top: 10px;
  font-size: 13px;
} */
.account_class .element i {
  padding-left: 25%;
  font-size: 2.5rem;
}

.list-item {
  cursor: pointer;
}
.list-item.active {
  background-color: #61ffa8;
}
.account_class .body li p {
  /* margin-left: 16%; */
}

.account_class .element .item-header {
  font-size: 18px;
  font-weight: 600;
}

.account_class .item-desc {
  font-size: 11px;
  font-weight: 500;
  color: gray;
}
.account_class ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.account_class ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.account_class ::-webkit-scrollbar-thumb {
  background: #67866c;
  border-radius: 10px;
}

/* Handle on hover */
.account_class ::-webkit-scrollbar-thumb:hover {
  background: #67866c;
}
