.px-9 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700;
}
.progressBar {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0;
}

.progressBar::before,
.progress {
  content: "";
  height: 4px;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #dcdcdc;
  counter-reset: step;
  z-index: -1;
}
.progress {
  background-color: #61ffa8;
  width: 0%;
}
.progress-step::after {
  content: attr(data-title);
  position: absolute;
  top: calc(100% + 0.5rem);
  font-size: 0.85rem;
  font-weight: 500;
  width: 25%;
  text-align: center;
  /* color: #666; */
}
.progress-step {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #dcdcdc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-step-active {
  background-color: #61ffa8;
}
.progress-step::before {
  counter-increment: step;
  content: counter(step);
}

.checkout .headings {
  font-size: 16px;
  color: #666;
}

.checkout hr {
  width: 50%;
  color: #336b4c;
  height: 2.5px;
}

.checkout .select-date p,
.checkout .select-timeslot p,
.checkout .delivery-mode p {
  font-weight: 600;
  font-size: 15px;
  /* width: 10vw; */
}

.checkout .active-item {
  background-color: #61ffa8;
}

.checkout .payment .right {
  border: 1px solid #61ffa8;
  border-radius: 5px;
  box-shadow: 0px 3px 5px #61ffa8;
}

.checkout .address {
  color: black;
}
.checkout .saved-address:hover {
  background-color: #61ffa8;
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px #61ffa8;
  /* transition: 1s; */
}

.not-available-slot {
  color: #bab6b6 !important;
}
.not-available-deliveryMode {
  color: #bab6b6 !important;
  border: 2px solid #bab6b6 !important;
}

.checkout .slot-day:hover,
.checkout .time-slot:hover {
  background-color: #61ffa8;
}
.checkout .owl-carousel .owl-nav button.owl-next {
  top: 10%;
}

.checkout .owl-carousel .owl-nav button.owl-prev {
  top: 10%;
}

.next-btn {
  border: 2px solid #047135 !important;
  border-radius: 3px;
  float: right;
  text-align: center;
  /* width: 20%; */
  color: #047135;
  background: white;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 20%);
  border-radius: 4px;
  font-size: 15px !important;
  font-weight: 600 !important;
  background-origin: padding-box;
  border: none;
}
.next-disabled {
  background-color: rgb(224, 217, 217) !important;
  color: grey !important;
  border: none !important;
}
.next-disabled:hover {
  background-color: rgb(224, 217, 217) !important;
  color: grey !important;
  border: none !important;
}
.next-btn:hover {
  background: #047135 !important;
  color: white !important;
}
.change {
  color: #047135;
  font-size: 14px !important;
  font-weight: 600;
  text-decoration: underline;
}
.address-field {
  width: 100%;
}
