/*****************
******************
DOWNLOAD METAMECHX APP
*****************
*****************/
/*=============================================================home-page=============================================================*/


/*download*/
.download .flex p
{
	font-size: 22px;
	font-weight: 400;
	line-height: 30px;
}
.link button
{
	font-size: 14px;
	font-weight: 700;
	color: #000;
	background: #61ffa8;
}
.link .m-number
{
	font-size: 12px;
	padding: 5px 20px;
	border: 1px solid #61ffa8;
	color: #ccc;
	border-radius: 4px;
}
