/*=================================================footer=================================================*/
footer {
  padding: 0px;
  padding-bottom: 0px;
  background: #242a36;
  color: #fff;
}
footer li a,
footer li .nav-link {
  line-height: 30px;
  font-weight: 400;
  color: #f5f5f5;
}
footer li a:hover,
footer li .nav-link:hover {
  color: #61ffa8;
  /* padding-left: 5px; */
  transition: 0.8s ease;
}
.foot-strip {
  background: #61ffa8;
  padding: 20px 3rem;
}
.btn-footer {
  background: #242a36;
  padding: 8px 30px;
  border: none;
  color: #fff;
  font-size: 12px;
  border-radius: 50px;
  font-weight: 700;
}

.foot-icons i {
  font-size: 30px;
  color: #61ffa8;
  margin-left: 30px;
}
.copy p {
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.footer .svg-inline--fa {
  color: #fff;
  margin-left: 30px;
  font-size: 24px;
}
.footer .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  width: 20%;
}
.footer .logo_tagline {
  font-size: xx-small;
  position: absolute;
  left: 4rem;
  color: #fff;
  height: 0;
}
.foot-icons .svg-inline--fa:hover {
  color: #61ffa8;
  margin-left: 30px;
  font-size: 24px;
}
