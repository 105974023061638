/*****************
******************
Car services
*****************
*****************/
/*=============================================================home-page=============================================================*/

/*car-services*/
.car-services .card {
  transition: 2s ease;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #d7ebdf;
  box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}
.car-services .card:hover {
  /* background: #61ffa8; */
  box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.04);
  transform: scale(1.1, 1.1);
  z-index: 1;
}
.car-services .card .obsent {
  display: none;
}
.car-services .card .obsent {
  transition: 2s ease;
}
.car-services .card:hover .obsent {
  display: block;
}
.car-services .card:hover .present {
  display: none;
}
.car-services .card-body {
  height: 4rem;
}
.car-services .card-image {
  padding-top: 5%;
  height: 6rem;
}
.car-services .card-image img {
  height: 100%;
}
/*car-services*/
