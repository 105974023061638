.element {
  /* padding:5px; */
  margin-bottom: 9px;
  border: 2px solid #61ffa8;
  border-radius: 7px;
}

.element div {
  padding: 0px;
}

.other-services div ul {
  padding: 0px;
}

.other-services .left .nav-link {
  padding: 0px;
  color: black;
}
.other-services .right {
  height: 115.7vh;
  overflow-y: scroll;
  box-shadow: 0px 0px 3px #61ffa8;
  margin: 13px;
}

.other-services .right::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
}

.other-services .right::-webkit-scrollbar-thumb {
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #c1c1c1; /* creates padding around scroll thumb */
}
.element p {
  margin-top: 15px;
}

.each-service {
  border: 2px solid #61ffa8;
  display: flex;
  margin-top: 10px;
  padding: 8px;
  border-radius: 6px;
}
.each-service .left-part {
  margin-right: 15px !important;
}
.each-service .left-part img {
  height: 80px;
}
.each-service button {
  background-color: #61ffa8;
  border: none;
  border-radius: 3px;
  float: right;
  text-align: center;
  /* width: 71px; */
}
.left-part .header {
  display: flex;
  justify-content: space-between;
}
.other-services .lists li {
  font-size: 11px;
  font-weight: 600;
}

.right-part .features div {
  padding: 0px;
}

.features ul li i {
  color: #61ffa8;
}
.each-service .features {
  padding-left: 10px;
}
.each-service .features ul {
  font-size: 14px;
  text-align: left;
}
.other-services .features .description ul li {
  font-size: 12px;
  font-weight: 500;
}
.other-services .active {
  background-color: #61ffa8;
}

.other-services .element .col-lg-9 p {
  font-size: 11px;
}
