/*****************
******************
HOW METAMECHX WORKS
*****************
*****************/
/*=============================================================home-page=============================================================*/

/*how-works*/
.how-works .works_des .row
{
	border: 1px solid #61ffa8;
	margin: 15px 0;
	border-radius: 20px;
	padding: 30px;
}
.works_des .car-icon
{
	height: 100px;
}
.works_des h5
{
	margin-top:20px;
}
.side_dots ul {
	 list-style: none;
	 margin: 0;
	 padding: 0;
	 position: relative;
}

  .side_dots ul:before {
	 content: " ";
	 border-left: 14px dotted #61ffa8;
	 display: inline-block;
	 width: 2px;

	 position: absolute;
	 left: 34px;
	 top: 10px;
	 height: calc(100% - 10px);
}

 .side_dots ul li {
	 position: relative;
	 padding-left: 30px;
	 margin-bottom: 148px;
     margin-top: 60px;
	 
}

 .side_dots ul li:before {
	 content: " ";

	 display: inline-block;
	 width: 80px;
	 height: 80px;
	 background: #61ffa8;
	 position: absolute;
	 left: 0;
	 top: 5px;
	 border-radius: 40px;
}