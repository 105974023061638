/*login*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.login_model .modal-dialog {
  float: right;
  height: cover;
}
.login_model .iti {
  width: 100%;
}
.iti__selected-flag {
  background: #fff;
  border-right: 1px solid #61ffa8;
}
.iti--separate-dial-code .iti__selected-flag {
  background: #fff !important;
}
.login_model {
  overflow: hidden;
}
.login_model .modal-content,
.login_model .modal-header {
  border: none;
}
.login_model .form-control:focus {
  box-shadow: 0px 0px 5px #61ffa8;
}
.fa-long-arrow-left {
  background: none;
  border: none;
  font-size: 28px;
}

.mobile_dial_code {
  padding: 14px !important;
  border: 1px solid #61ffa8 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.mobile_code,
#otp_code {
  padding: 14px !important;
  border: 1px solid #61ffa8 !important;
}

.mobile_code {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.mobile_code:focus,
#otp_code:focus {
  box-shadow: 0px 0px 5px #61ffa8;
}

.w-10 {
  width: 15% !important;
}

.modal_login {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.modal_verification {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.login-modal .modal-dialog {
  width: 745px;
  height: fit-content;
  /* left: 348px; */
  /* top: 223px; */
  background: #ffffff;
  padding: 13px;
  border-radius: 32px;
}

.login-modal .modal-content {
  border: none;
}

.login-modal .input-field {
  background: #f8f8f8;
  border: 2px solid #d2d2d2;
  border-radius: 8px;
  font-weight: 600 !important;
}
.login-modal .sign-up {
  font-size: 16px;
}
.login-modal .otp-btn {
  background: linear-gradient(180deg, #3eae6f 0%, #2e8253 100%);
  box-shadow: 1.86146e-15px 30.4px 60.8px rgb(11 31 20 / 24%),
    inset -5.79122e-16px -9.45778px 9.45778px #2c7c4f,
    inset 5.79122e-16px 9.45778px 9.45778px #40b473;
  border-radius: 4px !important;
  border: none;
  padding: 5px 45px;
}

.login-modal .otp-input-root input::selection {
  border: 2px solid green;
  border-radius: 15px;
}

.registraion-labels {
  font-size: 20px;
  font-weight: 600;
}
.err {
  border-color: red !important;
}
.resend-otp-btn button,
.resend-otp-btn span {
  border: none;
  color: grey;
  background: transparent;
}

.resend-otp-btn button:enabled {
  color: rgb(4, 95, 4) !important;
}
.resend-otp-btn div {
  align-items: center;
  justify-content: end !important;
}
.otp-fields input {
  border: 2px solid grey !important;
  /* border-right: none; */
  border-radius: 5px;
  width: 30px !important;
  height: 30px !important;
  box-shadow: 0px 0px 2px grey;
}

.otp-fields input:focus,
.input-field:focus {
  outline: 2.5px solid green !important;
  border: none !important;
  border-radius: 5px;
  font-weight: 600 !important;
}
.time-completed span {
  display: none;
}

.display-timer span {
  display: block;
}

.emailClass:focus {
  outline: 2.5px solid red !important;
  ;
}

.registerBtnValid {
  box-shadow: none !important;
  background-color: grey !important;
  background: grey !important;
  color: black !important
}