body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*{
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;

  font-weight: 700;
}
p {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
.col-lg-3 {
  margin: 10px 0;
}
.bxs i {
  color: #61ffa8;
}
:root {
  --light-green: #61ffa8;
  --dark-green: #379b63;
  --red: red;
}
/*Slides*/

.post-slide {
  background: #fff;
  margin: 20px 15px 20px;
  border-radius: 15px;
  padding-top: 1px;
  box-shadow: 0px 14px 22px -9px #bbcbd8;
}
.post-slide .post-img {
  position: relative;
  overflow: hidden;
}
.post-slide .post-img img {
  width: 100%;
  height: auto;
  transform: scale(1, 1);
  transition: transform 0.2s linear;
}
.post-slide:hover .post-img img {
  transform: scale(1.1, 1.1);
}
.post-slide .over-layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background: linear-gradient(
    -45deg,
    rgba(6, 190, 244, 0.75) 0%,
    rgba(45, 112, 253, 0.6) 100%
  );
  transition: all 0.5s linear;
}
.post-slide:hover .over-layer {
  opacity: 1;
  text-decoration: none;
}
.post-slide .over-layer i {
  position: relative;
  top: 45%;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 25px;
}
.post-slide .post-content {
  background: #61ffa8;
  padding: 2px 20px;
}
.post-slide .post-title a {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  display: inline-block;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
.post-slide .post-title a:hover {
  text-decoration: none;
  color: #3498db;
}
.post-slide .post-description {
  line-height: 24px;
  color: #808080;
  margin-bottom: 25px;
}
.post-slide .post-date {
  color: #a9a9a9;
  font-size: 14px;
}
.post-slide .post-date i {
  font-size: 20px;
  margin-right: 8px;
  color: #cfdace;
}
.post-slide .read-more {
  padding: 7px 20px;
  float: right;
  font-size: 12px;
  background: #2196f3;
  color: #ffffff;
  box-shadow: 0px 10px 20px -10px #1376c5;
  border-radius: 25px;
  text-transform: uppercase;
}
.post-slide .read-more:hover {
  background: #3498db;
  text-decoration: none;
  color: #fff;
}
.owl-controls .owl-buttons {
  text-align: center;
  margin-top: 20px;
}

.owl-carousel .owl-nav button.owl-prev {
  /* background: #61ffa8; */
  position: absolute;
  top: 40%;
  left: -30px;
  padding: 0 12px 0 10px !important;
  border-radius: 50px;
  /* box-shadow: 3px 14px 25px -10px #92b4d0; */
  transition: background 0.5s ease 0s;
}

.owl-carousel .owl-nav button.owl-next {
  /* background: #61ffa8; */
  position: absolute;
  top: 40%;
  right: -30px;
  padding: 0 10px 0 12px !important;
  border-radius: 50px;
  /* box-shadow: -3px 14px 25px -10px #92b4d0; */
  transition: background 0.5s ease 0s;
}

.banner .owl-carousel .owl-nav button.owl-next {
  right: 10px;
  top: 40%;
}
.banner .owl-carousel .owl-nav button.owl-prev {
  left: 10px;
  top: 40%;
}
.owl-carousel .owl-nav button.owl-prev:after,
.owl-carousel .owl-nav button.owl-next:after {
  content: "\f104";
  font-family: FontAwesome;
  color: #000;
  font-size: 26px;
}
.owl-carousel .owl-nav button.owl-next:after {
  content: "\f105";
}
@media only screen and (max-width: 1280px) {
  .post-slide .post-content {
    padding: 0px 15px 25px 15px;
  }
}

@media (max-width: 1366px) {
  .owl-carousel .owl-item img {
    width: 80px;
  }
}

@media (min-width: 350px) and (max-width: 980px) {
  .owl-carousel .owl-nav button.owl-next {
    top: 40%;
  }
  .owl-carousel .owl-nav button.owl-prev {
    top: 40%;
  }
}
