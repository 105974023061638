/*nav====================================*/
header {
  /* padding: 0 50px; */
  background: #242a36;
}
.navbar-brand img {
  height: 46px;
}
.green_text {
  color: #269961;
}
.header .logo {
  font-size: 28px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  width: 20%;
}
.header .logo_tagline {
  font-size: xx-small;
  position: relative;
  top: -0.5rem;
  left: 3rem;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  height: 0;
  margin-bottom: 3px;
  display: block;
}
nav .nav-item .nav-link {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin: 0 10px;
}
.nav-link::selection,
.selected {
  color: #61ffa8;
}
.navbar-brand {
  color: #fff;
}
.navbar-toggler {
  padding: 0px 10px;
}
.navbar-toggler-icon div {
  border: 0.5px solid #fff;
  margin: 6px 0;
}
/* .search_bar input
{
	border-radius: 60px;
    
    font-size: 12px;
    position: relative;
} */
.search_bar {
  position: relative;
  /* left: 10%; */
  flex: 0.7;
  display: flex;
  padding: 0px 5px 0px 10px;
  align-items: center;
  border-radius: 10px;
  background-color: whitesmoke;
}
.search_bar i,
ins {
  color: #61ffa8 !important;
  padding-right: 7px !important;
}
.search_bar input {
  padding: 4px 5px;
  border: none;
  background-color: transparent;
  outline: none;
}

.search_button {
  position: absolute;
  font-size: 22px !important;
  /* bottom: 4px !important; */
  color: #61ffa8 !important;
}

.search_button .form-control {
  font-size: 12px;
}
.btn-header {
  background: #61ffa8;
    padding: 0 0.8em;
    border: none;
    font-size: 0.85em;
    height: 35px;
    margin: auto;
    border-radius: 10px;
    font-weight: 700;
  /* margin-left: 36px; */
}

.cart {
  color: white;
  size: 10%;
}

.cart-count {
  color: #ccdbd3;
  margin: 0px;
  position: absolute;
  top: 0px;
  left: 56%;
  border: 3px solid #d77221;
  border-radius: 50%;
  padding: 0px 4px;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  background-color: #d77221;
  width: 20px;
}
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #242a36;
  overflow-x: hidden;
  transition: 0.2s;
}
.sidenav .menu-items {
  height: 500px;
  overflow-y: scroll;
}

.sidenav a {
  padding: 8px 8px 8px 8px;
  text-decoration: none;
  font-size: 20px;
  color: white;
  display: block;
  /* transition: 0.3s; */
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media (max-width: 480px) {
  .search_bar {
    margin-left: -2%;
  }
}

.address {
  color: white;
  /* margin-right: 10%; */
  width: 20%;
  cursor: pointer;
}
.address i {
  /* padding-right: 8%; */
  font-size: 1.4rem;
}
.address .address-desc{
  font-size: 17px;
  margin: 0px;
  width: 80%;
}
.address .location {
  color: gray;
  font-size: 12px;
  width: 80%;
}

.user-account .dropdown-menu[data-bs-popper] {
  top: 100%;
  left: initial;
  right: 25%;
  margin-top: 0.125rem;
}
.change-location-modal .modal-content{
  padding: 10px 20px 20px 10px;
    border-radius: 14px;
    /* height: 230px; */
}