/* .select-address{
        margin: 10% 5%;
} */
/* .select-address .header, .select-address .current-loc{
    display: flex;
    margin: 1% 0%;
} */
.select-address .current-loc button {
  margin-top: 1% !important;
  padding: 1%;
}
.select-address .current-loc p {
  margin: 0% !important;
}
.select-address button {
  border: none;
  margin-right: 1% !important;
  padding: 0.5%;
  background-color: #61ffa8;
  border-radius: 20%;
}

/* h4{
    color:#61ffa8
} */
.select-address input {
  border: none;
  width: 100% !important;
  outline: none;
  padding: 1%;
  /* margin-bottom: 2%; */
  /* border-radius: 5px; */
}

.select-address .search-location {
  border: 2px solid gray;
  border-radius: 15px;
  padding: 10px;
}

.select-address .fa-search-location {
  color: gray;
  font-size: 21px;
}

.select-address .fa-location-arrow {
  font-size: 15px;
  color: gray;
}

.select-address .search-results {
  color: gray;
  font-size: 15px;
  font-weight: 400;
}

.select-address .main-text {
  font-size: 17px;
  font-weight: 500;
}
.select-address .secondary-text {
  font-weight: 400;

  font-size: 15px;
  color: gray;
}
.select-address .saved-address .list {
  margin: auto;
  height: 360px;
  /* width: 300px; */
  overflow-y: auto;
}
.select-address .saved-address .list ul {
  padding: 0%;
}
.select-address .saved-address li,
.select-address .current-loc {
  cursor: pointer;
}
.select-address .saved-address .list::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.select-address .saved-address .list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.select-address .saved-address .list::-webkit-scrollbar-thumb {
  background: #67866c;
  border-radius: 10px;
}

/* Handle on hover */
.select-address .saved-address .list::-webkit-scrollbar-thumb:hover {
  background: #67866c;
}
