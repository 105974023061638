.service-center {
  background-color: #ffffff !important;
  color: black !important;
  padding: 2%;
  margin-top: 2%;
  border: 2px solid #61ffa8;
}

.service-center .right span {
  padding-top: 1.5%;
  color: #61ffa8 !important;
}

.service-center .right {
  margin-left: 5%;
}

.service-center .footer {
  display: flex;
  justify-content: space-between;
}

.service-center .footer .left,
.service-center .footer .right {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}
/* .service-center .footer .right{
    margin-right: -25%;
} */
.service-center button {
  background-color: #61ffa8;
  border: none;
  border-radius: 3px;
  float: right;
  text-align: center;
  /* width: 90px; */
}
.service-center ul {
  font-size: 13px;
}
.service-center .garage-features {
  background-color: #c3dbce;
  border-radius: 6px;
  text-align: left;
  margin-bottom: 10px;
  padding: 1% 6%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.service-center .features-mobile {
  display: none;
}
.details {
  color: #047135 !important;
}

.add-cart-btn {
  border: 2px solid #047135 !important;
  border-radius: 3px;
  float: right;
  text-align: center;
  /* width: 71px; */
  color: #047135 !important;
  background: white !important;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 20%);
  border-radius: 4px;
  font-size: 15px !important;
  font-weight: 600 !important;
  background-origin: padding-box;
  border: none;
}
.add-cart-btn:hover,
.added-item {
  background: #047135 !important;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 20%);

  color: white !important;
}

.service-center .footer .current-price {
  font-size: 16px;
}

.service-center .footer .left .actual-price {
  color: gray;
}

@media only screen and (max-width: 480px) {
  .service-center .footer .current-price {
    font-size: 16px !important;
  }

  .service-center .footer .left .actual-price {
    font-size: 12px;
  }

  .service-center .footer .right .details {
    display: none;
  }
  .service-center .features-mobile {
    display: block;
  }
  .service-center .garageName h5 {
    font-size: 14px;
  }
  .service-center .garageName {
    margin-bottom: 0px !important;
  }
  div > .garageName {
    padding: 0px;
  }
  .service-center li {
    font-size: 10px;
  }
  .service-center ul {
    margin-bottom: 0px;
  }
  .service-center .features,
  .service-center .price {
    display: none;
  }
  .change-service-center-confirm-modal .modal-body {
    height: 14rem;
  }
}
