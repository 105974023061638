/*****************
******************
BENEFITS OF USING METAMECHX
*****************
*****************/
/*=============================================================home-page=============================================================*/

/*benefits*/
.benefits .card {
  padding: 40px 60px;
  text-align: center;
  border: none;
}
.benefits .card .benefits-image {
  width: 100px;
  height: 100px;
}
.benefits .card i {
  font-size: 130px;
}
.benefits .card p {
  font-size: 12px;
}
.benefits .card .card-body {
  display: contents;
}
