/* .post-content img {
  width: 39% !important;
} */
/* .post-content .rating{
object-fit: cover;
    width: 10px !important;
    height: 10px;
    overflow: hidden;
} */

.post-content ul {
  /* list-style: none;  */
  /* margin: -12px; */
  padding: 1px;
  display: inline-flex;
  margin: 0px;
}

/* .post-content .reviews{
    margin-top:"-8%"
} */
