.elements {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 0%;
  margin-top: 8px; */
}
/* i{
    color: white;
} */

.elements button {
  background: none;
  border: none;
}
.elements i {
  color: white;
}
.fixed-bottom {
  padding: 15%;
  display: none;
  height: 50px;
  padding-top: 0.5rem;
  bottom: -2px;
  padding: 13px !important;
}

.fixed-bottom .selected {
  color: #61ffa8;
  /* border: 1px solid #61ffa8; */
  border-radius: 5px;
}
.footer-cart-count {
  color: #ccdbd3;
  margin: 0px;
  position: absolute;
  top: -10px;
  left: 56%;
  border: 3px solid #d77221;
  border-radius: 50%;
  padding: 0px 4px;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  background-color: #d77221;
  width: 20px;
}
