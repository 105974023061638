.select-address input {
  width: 75%;
  outline: none;
  padding: 1%;
  /* margin-bottom: 2%; */
}

.select-address .saved-address .list {
  margin: auto;
  height: 360px;
  /* width: 300px; */
  overflow-y: auto;
  overflow-x: hidden;
}

.select-address .saved-address .list ul {
  padding: 0%;
}

.dropDownBox {
  position: absolute;
  z-index: 1;
  background-color: white;
  width: 38%;
}

.savedAddress {
  height: 500px;
  overflow-y: scroll;
}

.savedAddress::-webkit-scrollbar {
  width: 5px;
}

.savedAddress::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.savedAddress::-webkit-scrollbar-thumb {
  background: #67866c;
  border-radius: 10px;
}

.savedAddress::-webkit-scrollbar-thumb:hover {
  background: #67866c;
}

.add-address-btn {
  border: 2px solid #047135 !important;
  border-radius: 3px;
  float: right;
  text-align: center;
  width: 100% !important;
  color: #047135 !important;
  background: white !important;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 20%);
  border-radius: 4px;
  /* font-size: 15px !important; */
  font-weight: 600 !important;
  background-origin: padding-box;
  border: none;
}

.add-address-btn:hover {
  color: white !important;
  background: #047135 !important;
}

.addressBtns {
  border: 2px solid #047135 !important;
  color: #047135 !important;
  background: white !important;
  font-size: 15px;
  font-weight: 600 !important;
  width: 100%;
}

.addressBtns:hover {
  border: 2px solid #047135 !important;
  color: white !important;
  background: #047135 !important;
  font-size: 15px;
  font-weight: 600 !important;
  width: 100%;
}

.defaultButton {
  border: 2px solid #047135 !important;
  color: white !important;
  background: #047135 !important;
  font-size: 15px;
  font-weight: 600 !important;
  width: 100%;
}

.each-saved-address {
  width: 44.666%;
}

.address-save-btn {
  background-color: #047135;
  border-radius: 10px;
  color: white;
}

.cancelBtn {
  background: red !important;
  border-radius: 10px !important;
  color: white !important;
  font-size: 15px !important;
  font-weight: 600 !important;
  /* width: 100%; */
}