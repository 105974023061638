.common_border {
  border: 2px solid #61ffa8;
  outline: none;
}

.my_vehicles .owl-carousel .owl-nav button.owl-prev {
  background: #61ffa8;
  position: absolute;
  top: 26%;
  left: -18px;
  padding: 0px 12px 0 10px !important;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  box-shadow: 3px 14px 25px -10px #92b4d0;
  transition: background 0.5s ease 0s;
}

.my_vehicles .owl-carousel .owl-nav button.owl-next {
  background: #61ffa8;
  position: absolute;
  top: 26%;
  right: -18px;
  padding: 0px 12px 0 10px !important;
  width: 35px;
  height: 35px;
  border-radius: 50px;
  box-shadow: 3px 14px 25px -10px #92b4d0;
  transition: background 0.5s ease 0s;
}
.my_vehicles .save_btn {
  margin-left: 40%;
}
.my_vehicles .saved_vehicles {
  max-height: 460px;
  overflow-y: scroll;
}
.my_vehicles .year_dropdown {
  height: 157px;
  overflow-y: scroll;
}

.my_vehicles .year_dropdown li {
  cursor: pointer;
}
.my_vehicles .year_dropdown li:hover {
  background-color: #61ffa8;
}
.my-vehicles-list .remove {
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}
.my-vehicles-list {
  height: 100%;
  overflow-y: scroll;
  /* color: var(--dark-green); */
}
.my-vehicles-list .each-vehicle {
  border: 1px solid #61ffa8;
  border-radius: 5px;
}

.my-vehicles-list .vehicle-name,
.my-vehicles-list .vehicle-number {
  font-size: 15px;
  color: #e8b92e;
}

.my-vehicles-list .service-now {
  color: #1396f3;
  text-decoration: underline;
}
.my-vehicles-list .service-now:hover {
  color: #047135;
  /* transition: 0.25s; */
}

.my-vehicles-list .remove {
  color: rgb(245, 58, 58);
}
.my_vehicles .add-vehicle-btn {
  border: 2px solid #047135 !important;
  border-radius: 3px;
  float: right;
  text-align: center;
  width: 100% !important;
  color: #047135 !important;
  background: white !important;
  box-shadow: 0px 5px 5px rgb(0 0 0 / 20%);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600 !important;
  background-origin: padding-box;
  border: none;
}
.my_vehicles .add-vehicle-btn:hover {
  color: white !important;
  background: #047135 !important;
}
