.wallet p {
  margin-top: 10px;
  font-size: 13px;
}
/* .section1 .right-part div,
.section2 .right-part {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.section1 .right-part {
  border-radius: 10px;
  background-color: #61ffa8;
}

.section1 .right-part h1 {
  text-align: center;
}

.section2 {
  margin-top: 3%;
}
.section2 .right-part {
  border: 2px solid #61ffa8;
  border-radius: 10px;
}

.section2 .right-part button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 2px solid var(--dark-green);
  color: var(--dark-green);
  background-color: transparent;
  border-radius: 5px;
}
.section3 {
  margin-top: 5%;
}
.paymentOptions .right-part select {
  border: 1px solid #61ffa8;
  padding: 3%;
  /* text-align: center; */
  outline: 1px solid #61ffa8;
  color: darkgray;
}

.section3 .right-part button {
  width: 207px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #61ffa8;
  /* background-color: #61ffa8; */
}

.section3 .right-part .btn-primary {
  color: darkgray;
  background-color: #ffff;
  border-color: #61ffa8;
}

.walletHistory {
      margin-top: 5%;
    
}
.wallet-history-list{
  height: 310px;
    overflow-y: scroll;
}

.walletHistory .hist {
  background: #ffffff;
  border: 1px solid #d7ebdf;
  box-shadow: 0px 15px 16px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  width: 100%;
  /* padding: 1%; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.walletHistory .credited {
  color: var(--dark-green);
}

.walletHistory .debited {
  color: var(--red);
}
